import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Inner from "../../layout/pageInner/Inner"
import { graphql, useStaticQuery } from "gatsby"
import Breadcrumbs from "../../atoms/breadcrumb"

const Heading = ({
  image,
  subTitle,
  titleExtra,
  location,
  product,
  titleOverload = false,
}) => {
  const { file } = useStaticQuery(PLACEHOLDER)
  const { pathname } = location

  const title = titleOverload
    ? titleOverload
    : React.useMemo(() => {
        if (pathname) {
          const noSlash = pathname.split("/").filter((x) => x !== "")
          const lastElement = noSlash[noSlash.length - 1]
          return lastElement
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        }
      }, [pathname])

  const AllContent = () => (
    <Inner>
      <Flex>
        <Title
          dangerouslySetInnerHTML={{
            __html: title == "Faq" ? title.toUpperCase() : title,
          }}
        />
        {titleExtra && <TitleAppend>{titleExtra}</TitleAppend>}
        <Breadcrumbs pathname={pathname} color="white" product={product} />
        <SubTitle>{subTitle}</SubTitle>
      </Flex>
    </Inner>
  )

  // Proper Gatsby image
  if (image) {
    return (
      <FixedContainer>
        <StyledBackgroundImage
          style={{ position: "absolute" }}
          image={image}
          alt="background"
        />
        <StyledWrap>
          <AllContent />
        </StyledWrap>
      </FixedContainer>
    )
  } else {
    // Use fallback image
    return (
      <FixedContainer>
        <StyledBackgroundImage
          style={{ position: "absolute" }}
          image={file.childImageSharp.gatsbyImageData}
          alt="background"
        />
        <StyledWrap>
          <AllContent />
        </StyledWrap>
      </FixedContainer>
    )
  }
}

export default Heading

const Wrap = styled.div`
  min-height: 300px;
  background: linear-gradient(
    127.54deg,
    rgba(22, 34, 50, 0.76) 0%,
    rgba(34, 50, 71, 0.48) 100%
  );
`
const StyledWrap = styled(Wrap)`
  position: relative;
  z-index: 1;
`
const FixedContainer = styled.div`
  position: relative;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 300px;
  justify-content: center;
`

const Title = styled.h1`
  color: white;
  font-family: Roboto;
  font-size: 48px;
  font-weight: 300;
  line-height: 57px;
  margin-bottom: 10px;
`
const TitleAppend = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 100;
  line-height: 36px;
  margin-bottom: 10px;
`
const SubTitle = styled.p`
  opacity: 0.9;
  color: #ffffff;
  font-family: Roboto;
  font-size: 18px;
  line-height: 26px;
  margin-top: 20px;
`

const Crumbs = styled.div`
  color: white;
  a {
    color: #223247;
    font-family: Roboto;
    font-size: 16px;
    line-height: 23px;
  }
`
const StyledBackgroundImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  z-index: 0;
  top: 0;
  left: 0;
`
const StyledBackground = styled(BgImage)``

const PLACEHOLDER = graphql`
  {
    file(relativePath: { eq: "header-fallback.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
