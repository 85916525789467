import React from "react"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import Heading from "../zzz/organisms/heading"
import { graphql, useStaticQuery } from "gatsby"
import ProductList from "../components/Products/ProductList"
import LinkWrapper from "../utils/linkWrapper"
import Inner from "../zzz/layout/pageInner/Inner"
import styled from "styled-components"
import { useQueryParam, NumberParam } from "use-query-params"
import { slugify } from "../lib/stringHandling"
import SvgIcon from "../zzz/atoms/icons/svg-icon"
import ArrowRight from "../zzz/atoms/icons/files/arrow-right.jsx"

const discountPercentageLimit = 0.25

const BestDealsPage = ({ location }) => {
  const [pageNum, setPageNum] = useQueryParam("page", NumberParam)
  const graphqlData = useStaticQuery(PRODUCTS)
  const bestDealsProducts = graphqlData.allWcProducts.nodes
  const {
    localWpGraphQlPages: {
      pageId: pageId,
      seo: seo,
      title: title,
      heading: { subTitle },
      featuredImage: {
        imageFile: {
          localFile: {
            childImageSharp: { gatsbyImageData },
          },
        },
      },
      bestDeals: {
        contentRow: { blurb, body },
        bestDealsSpecialsRow,
        featuredBeds,
      },
    },
  } = graphqlData

  return (
    <Layout location={location}>
      <Background>
        <SEO title={title} location={location} post_id={pageId} seo={seo} />
        <Heading
          subTitle={subTitle}
          location={location}
          image={gatsbyImageData}
        />
        {!pageNum || pageNum == 1 ? (
          <Wrap>
            <Inner className="inner">
              <div dangerouslySetInnerHTML={{ __html: body }} />
              <div
                className="blue"
                style={{
                  borderRadius: "4px",
                  background: "#1a293b",
                  fontSize: "21px",
                  color: "white",
                  padding: "20px",
                }}
                dangerouslySetInnerHTML={{ __html: blurb }}
              />
            </Inner>
          </Wrap>
        ) : (
          <Gap />
        )}
        <Inner>
          <ProductList
            showProductDeals
            usePagination
            productList={bestDealsProducts}
            productsPerLoad={20}
            sizesFilter={[]}
            discountPercentageLimit={discountPercentageLimit}
          />
        </Inner>
        {!pageNum || pageNum == 1 ? (
          <Inner>
            <TextWrap style={{ paddingTop: "60px" }}>
              <Title>{bestDealsSpecialsRow.title}</Title>
              <SubTitle>{bestDealsSpecialsRow.subTitle}</SubTitle>
            </TextWrap>
            <CardGrid>
              {featuredBeds.map((feature) => (
                <CardWrap key={slugify(feature.title)}>
                  <h2>
                    {feature.title}
                    <LinkWrapper
                      to={
                        feature.title.includes("Beds")
                          ? "/all-brands/" + slugify(feature.title) + "/"
                          : "/beds/"
                      }
                    >
                      <div>View Range</div>
                      <SvgIcon
                        SvgComponent={ArrowRight}
                        hue="tusk"
                        shade="060"
                      />
                    </LinkWrapper>
                  </h2>
                  <p>{feature.description}</p>
                </CardWrap>
              ))}
            </CardGrid>
          </Inner>
        ) : (
          <LittleGap />
        )}
      </Background>
    </Layout>
  )
}

const Gap = styled.div`
  margin-top: 60px;
`
const LittleGap = styled.div`
  margin-top: 30px;
`

const Background = styled.div`
  background: #f0f1f3;
`
const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  padding: 60px 0;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const CardWrap = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 1px 1px #2232452e;
  background: white;
  overflow: hidden;

  h2 {
    background: #223246;
    color: white;
    font-weight: 900;
    font-size: 20px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: #bac0c5;
      border-radius: 4px;
      border: 1px solid #bac0c5;
      display: flex;
      flex-direction: row;
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      transition: 0.2s all ease-in-out;
      cursor: pointer;
      &:hover {
        transform: translateX(3px);
      }
      div {
        padding: 5px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > span.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #bac0c5;
        padding: 5px;
      }
    }
  }
  p {
    padding: 20px;
  }
`

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Wrap = styled.div`
  padding: 60px 0;
  background: #f0f1f3;

  .blue {
    font-size: 20px;
    p {
      font-size: 21px;
    }
  }

  .inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
const TextWrap = styled(LinkWrapper)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
`
const Title = styled.h2`
  color: #223247;
  border-bottom: 1px solid red;
`
const SubTitle = styled.h3`
  color: #223247;
  font-size: 16px;
  margin-top: 10px;
`
const Button = styled.div`
  background: #fd3237;
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  margin: auto;
  &:hover {
    transform: translateX(2px);
  }

  > div {
    padding: 5px 15px;
    font-size: 20px;
  }

  > span {
    padding: 8px;
    background: #da2e32;
  }
`
// ==================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ==================

const PRODUCTS = graphql`
  {
    ## ProductFilter: All beds, bedroom furniture and mattresses products
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
        categories: { elemMatch: { slug: { in: ["beds"] } } }
      }
    ) {
      nodes {
        ...ProductCardInfo
      }
    }
    localWpGraphQlPages(slug: { eq: "bed-specials" }) {
      pageId
      title
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      heading {
        subTitle
      }
      featuredImage {
        sourceUrl
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
      bestDeals {
        contentRow {
          body
          blurb
        }
        bestDealsSpecialsRow {
          title
          subTitle
        }
        featuredBeds {
          title
          description
        }
      }
    }
  }
`

export default BestDealsPage
